<template>
  <div class="my-auto">
    <v-card class="d-flex flex-column align-center mx-auto" width="fit-content">

      <v-card-text>
        <v-img max-width="300px" :src="require('@/assets/logos/logo-lg.png')">
        </v-img>
      </v-card-text>

      <v-card-text>
        <h2 class="text-center">LOGIN</h2>
      </v-card-text>

      <v-card-text>
        <v-form class="d-flex flex-column" @submit.prevent="login">
          <v-text-field v-model="state.email" type="email" outlined dense label="Email"></v-text-field>
          <v-text-field v-model="state.password" type="password" outlined dense label="Password"></v-text-field>
          <v-btn type="submit" :disabled="!canLogin" :loading="state.isLoggingIn" class="mx-auto" color="primary" block>
            Login</v-btn>
        </v-form>
      </v-card-text>
      
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import { useUser } from "@/composables/user/user";
import { reactive, computed } from "@vue/composition-api";

export default {
  name: "Login",
  setup() {
    const state = reactive({
      email: "",
      password: "",
      isLoggingIn: false,
      errorMsg: "",
    });

    const { setUserData } = useUser();

    const canLogin = computed(() => {
      if (!state.email || !state.password) return false;
      return true;
    });

    async function login() {
      try {
        state.isLoggingIn = true;
        const { data: loginResponse } = await axios.post("/auth/login", {
          email: state.email,
          password: state.password,
        });

        localStorage.setItem("accessToken", loginResponse.data.token);
        localStorage.setItem( "userData", JSON.stringify(loginResponse.data));
        setUserData(loginResponse.data);
        this.$router.push({ name: "dashboard" });
      } catch (err) {
        alert(err.response.data.message)
        state.errorMsg = err.response.data.message;
      } finally {
        state.isLoggingIn = false;
      }
    }

    return {
      state,
      canLogin,
      login,
    };
  },
};
</script>
